import './bootstrap';
import '../css/app.css';

import { createRoot } from 'react-dom/client';
import { createInertiaApp } from '@inertiajs/react';
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers';
import * as Sentry from "@sentry/react";

const appName = import.meta.env.VITE_APP_NAME || 'Laravel';

try {
    Sentry.init({
    dsn: "https://61c01a04fc94664fdc622a8858c2d1e7@o4504933910511616.ingest.us.sentry.io/4507407276310528",
    integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration({
            networkDetailAllowUrls: [window.location.origin],
            mask: [".mask-me"],
            unmask: [".unmask-me"],
            maskAllText: false
        }),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    // tracePropagationTargets: ["localhost", "pre.esportaldiabcn.cat", "esportaldiabcn.cat"],
    tracePropagationTargets: [ "esportaldiabcn.cat"],
    // Session Replay
    replaysSessionSampleRate: 1.0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});
}catch(err){
    console.log("sentry initialization failed with the error: " + err);
}


createInertiaApp({
    title: (title) => `${title} - ${appName}`,
    resolve: (name) => resolvePageComponent(`./pages/${name}.jsx`, import.meta.glob('./pages/**/*.jsx')),
    setup({ el, App, props }) {
        const root = createRoot(el);

        root.render(<App {...props} />);
    },
    progress: {
        color: '#4B5563',
    },
});
